<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { ProfileVerificationStatus } from '@/core/constants/enums';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    USER_GET_ACCOUNT_VERIFICATION_LIST,
    USER_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE
  } from '@/core/store/user.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'AccountVerificationList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.accountVerification'),
            route: { name: ROUTE_NAME.ACCOUNT_VERIFICATION }
          },
          { title: i18nHelper.getMessage('label.accountVerificationList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.USER,
        actions: {
          create: ACTION.USER_CREATE,
          read: ACTION.USER_READ,
          update: ACTION.USER_UPDATE,
          delete: ACTION.USER_DELETE
        }
      },
      filters: [
        {
          label: 'firstName',
          key: 'firstName',
          value: null
        },
        {
          label: 'lastName',
          key: 'lastName',
          value: null
        },
        {
          label: 'verificationStatus',
          key: 'verificationStatus',
          value: null,
          options: listService.getVerificationStatusList(
            CONSTANTS_DATA.verificationStatus
          ),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'memberType',
          key: 'role',
          value: null,
          options: listService.getDynamicList(CONSTANTS_DATA.userRole),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'verificationSubmittedDateRange',
          key: 'verificationSubmittedDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        }
      ],
      actions: {
        viewList: {
          module: 'user',
          state: 'accountVerificationList',
          action: USER_GET_ACCOUNT_VERIFICATION_LIST,
          initialStateAction: USER_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE,
          title: i18nHelper.getMessage('label.getAccountVerificationList')
        },
        viewDetails: {
          routeName: ROUTE_NAME.ACCOUNT_VERIFICATION_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'fname',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'verificationStatus',
          name: 'verificationStatus',
          active: true,
          sortable: true,
          class: 'text-capitalize',
          layout: APP_COMPONENT_NAME.APP_STATUS_LABEL,
          config: {
            enumKey: 'enumProfileVerificationStatus',
            statusColor: (value) => {
              let color = {
                [ProfileVerificationStatus.SUBMITTED]: 'primary',
                [ProfileVerificationStatus.REJECTED]: 'danger',
                [ProfileVerificationStatus.APPROVED]: 'success'
              };

              return color[value];
            }
          }
        },
        {
          key: 'role',
          name: 'memberType',
          active: true,
          sortable: true,
          class: 'text-capitalize'
        },
        {
          key: 'verificationSubmittedAt',
          name: 'submittedDateTime',
          active: true,
          sortable: true,
          sortField: 'verificationSubmittedAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
    })
  };
</script>

<style></style>
